@import '../../variables';

:root {
  /* Make the mention background blue. */
  --ck-color-mention-background: transparent;

  /* Make the mention text dark grey. */
  --ck-color-mention-text: default;
}

.ck-toolbar {
  border: none !important;
  border-bottom: 0 !important;
  background-color: #fff !important;
}

.ck-content {
  background-color: white;
  width: calc(21cm + 2px);
  min-height: calc(29.7cm + 2px);
  height: fit-content;
  padding: 2cm 1.2cm !important;
  margin: 2.5rem;
  border: 1px $gray-400 solid !important;
  box-shadow: 0 0 5px #00000019;
  box-sizing: border-box;

  p {
    font-size: 14px;
  }
}

.document-editor__toolbar {
  width: 100%;
}

.toolbar-container-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(206, 212, 218);
  border-bottom: none;
  flex-wrap: wrap;

  .autosave-switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    margin: 0 10px;
    padding: 6px 0;
    flex: 1 1 80px;
    min-width: 0;

    .form-check-input {
      flex: 0 0 2.5rem;
      height: 2em;
      margin-top: 0;
      margin-left: 0;
    }

    .form-check-input:disabled+.form-check-label {
      opacity: 0.8;
      cursor: default;
    }

    .form-check-label {
      margin-right: 1em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      flex-shrink: 1;
      min-width: 0;
    }
  }
}

.editor-outer__spinner-container {
    position: sticky;
    top: 50%;
    height: 0;
    width: 3rem;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    .editor-outer__spinner {
      width: 3rem;
      height: 3rem;
    }
  }

.editor-loading {
  filter: brightness(0.8);
  background-color: #fff;
  pointer-events: none;
}


.editor-container {
  width: 100%;
  border-right: 0;
  box-sizing: border-box;
  position: relative;
  overflow: auto;
  background-color: #eff2f7;

  .ck-content {
    margin: 2.5rem auto;
  }
}

.row-editor {
  border: 1px $gray-400 solid;
  display: flex;
  position: relative;
  justify-content: center;
  overflow-y: auto;
  max-height: 80vh;
}

.ck.ck-list__item .ck-button .ck-button__label,
.ck-fontsize-option {
  font-size: 1rem !important;
  font-weight: normal !important;
}

// Editor controls

.editor__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    font-size: 11pt;
    color: $blue;
  }

  &__left {
    display: flex;
    align-items: stretch;

    .template_title {
      display: flex;
      align-items: center;
    }

    button {
      padding: 0.2rem !important;
      display: flex;
      align-items: center;
      height: 100%;

      &:first-of-type {
        margin-right: 16px;
      }
    }

    button:not(:last-child) {
      margin-right: 0.4rem;
    }
  }

  .plus-icon {
    font-size: 8px;
  }

  .editor__buttons-container {
    display: flex;

    button:first-of-type {
      margin-right: 16px;
    }
  }
}

.card-body {
  &.editor__container_inactive {
    opacity: 0.5;
    pointer-events: none;
    .editor-container {
      :last-child {
        ::before {
          content: 'Возможность транскрибации записи будет доступна после остановки записи';

          display: flex;
          justify-content: center;
          color: black;
          font-size: large;
          padding: 25% 0 75% 0;
        }
      }
    }
  }
}
