//
// _player-recording.scss
//
.player-recording {
  height: 154px;
  display: flex;
  align-items: center;

  &.none {
    color: #34c38f;
    cursor: pointer;
  }
  &.run {
    color: #34c38f;
  }
  &.pause {
    color: #6a7187;
  }
  &.stop {
    color: #6a7187;
  }

  .player-gif {
    height: 101px;
    width: 50%;
  }
  .player-image {
    background-position: center;
    background-image: url('../../../images/waveform/waveform.svg');
    background-repeat: repeat-x;
    height: 101px;
    width: 49%;
    margin-right: 1%;
  }

  i {
    margin-right: 5px;
    font-size: 27px;
  }

  &-status {
    font-size: 27px;
    line-height: 1;
  }

  &-time {
    font-size: 12px;
    color: #6a7187;
    padding-right: 20px;
  }
}
