.cases-modal_veil {
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  justify-content: center;
  overflow-y: scroll;
  padding-top: 30px;
  padding-bottom: 70px;
  background-color: rgba($color: #000000, $alpha: 0.4);
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}
.cases-modal {
  width: 500px;
  &__card {
    position: relative;
    border-radius: 8px;
    padding-left: 0;
  }

  .h3 {
    margin-bottom: 0;
    font-size: 24px;
  }
  &__header {
    &__heading-container {
      display: flex;
    }

    &__logo {
      border-radius: 8px;
    }
  }

  .form-field input[type='date'] {
    background-image: none;
  }

  .formGroup > .Row {
    margin-bottom: 14px;

    .bold {
      font-weight: bold;
    }
  }

  .form-checkbox-row {
    margin-bottom: 20px !important;
  }

  .form-checkbox-container {
    display: flex;
    align-items: center;
    padding: 0;

    input {
      min-width: 16px;
    }

    label {
      margin: 0 0 0 20px;
    }
  }

  .formBlock {
    width: 950px;
  }

  .form-cardBody {
    padding: 36px 40px;

    .form-horizontal {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 60px;
      row-gap: 36px;
    }

    .Row {
      padding: 0 !important;
      width: 100%;
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: 40% 60%;
    }
  }
  .card_body {
    padding-left: 0 !important;
  }
  .formBlock {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .formBlock > div {
    padding: 0 !important;
  }
  button {
    font-size: 13px;
  }

  form {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  input {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    position: relative;
  }
  input[type='checkbox'] {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin: 0;
    border-radius: 4px;
    height: 16px !important;
    width: 16px !important;
  }
  input[type='checkbox']:active {
    background: #556ee6;
    border: 1px solid #556ee6;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    background-image: url(../../../images/courtCases/bx-calendar.svg);
    width: 23px;
    height: 21px;
    position: absolute;
    right: 4%;
    top: auto;
    bottom: auto;
    padding: 0;
  }

  .align-center {
    display: flex;
    align-items: center;
  }
}
