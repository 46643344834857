//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed $gray-400;
  background: $card-bg;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  .dz-message {
    font-size: 24px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
}
