//
// Page-transcription
//

.page-title-box {
  margin-bottom: 20px;

  h4 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px !important;
  }
}
