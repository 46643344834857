.deleteCasesModal {
  .modal-content {
    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 18px;
    }

    width: 450px;
    height: 420px !important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 60px 50px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
  }

  .btn {
    width: 103px;
    height: 36px;
  }
}

.table-btnWrapper {
  display: flex;
  justify-content: flex-end;

  .JudicialSectors-table-container {
    margin-left: 24px;
  }
}

.judicialcases-content-wrapper {
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  padding: 45px 25px;
}

.judicialcases-header {
  display: flex;
  justify-content: space-between;
}

.courtcases-table-container {
  display: flex;
  justify-content: flex-end;

  align-items: center;
}

.judicialcases-addbtn {
  height: 36px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 0 50px;
}

.judicialcases-header-search {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.judicialcases-editbtn {
  padding: 8px 23px;
}

.judicialcases-header-search-input {
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  max-width: 168px;
  height: 27px;
  margin-left: 5px;
}

.JudicialSectors-table-container {
  text-align: center;
}

.courtcases-table-thitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.courtcases-table-header {
  display: flex;
  justify-content: space-between;
}

iframe {
  display: none;
}

.courtcases-table-header-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  color: #495057;

  white-space: nowrap;
  cursor: pointer;
}

.courtcases-table-header-item-sorted {
  .courtcases-table-thitem-sortarrows-wrapper,
  .courtcases-table-thitem-sortarrow-up,
  .courtcases-table-thitem-sortarrow-down {
    transform: rotate(180deg);
  }
}

.courtcases-table-editbtn-wrapp {
  display: grid;
  place-items: center;
}

.courtcases-table-thitem-nodate {
  max-width: 60px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #495057;
}

.judicialsectors-table-wrapper {
  border-top: 1px solid #eff2f7;
  margin-top: 25px;
}

.courtcases-table-row {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #495057;


  .controls {
    cursor: pointer;
    margin: 5px;
  }
}
